<template>
  <div v-if="getTemplateDetails" class="px-1 py-2">
    <div style="background: #e6e6e6" class="w-100">
      <div class="d-flex" :class="isMobSmallScreen ? 'direct-row' : 'direct-col'">
        <div class="char-applied-bg d-flex flex-column px-4 pb-2">
          <div
            class="character-profile-header position-relative mt-2 mb-2"
            :class="isMobSmallScreen ? '' : 'set-padding'"
          >
            <div class="cover-applied"></div>
            <div class="rest-applied position-relative py-1">
              <div class="d-flex flex-column align-items-center spacing">
                <ion-button class="px-2 small-char-button"></ion-button>
                <ion-button class="px-2 small-quote-button"></ion-button>
                <ion-button class="px-2 small-applied-button"></ion-button>
              </div>
            </div>
          </div>
          <div class="character-section position-relative" :class="isMobSmallScreen ? '' : 'set-padding mb-2'">
            <div class="resttitle-applied position-relative">
              <div class="d-flex flex-column py-1 align-items-center">
                <ion-button class="px-2 mt-1 small-app-title-button"></ion-button>
                <ion-button class="px-2 small-default-button"></ion-button>
                <ion-button class="px-2 small-default-button"></ion-button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100" style="color: black">
          <div class="d-flex px-2 py-2" :class="isMobSmallScreen ? 'flex-column' : 'change-app-btn'">
            <ion-button
              class="w-100 applied-btn clickable-item-hov"
              :disabled="isApplying"
              @click="applyDesign('apply')"
            >
              <ChLoading size="sm" v-if="isApplying" class="spinner" />
              <span v-else>Apply</span></ion-button
            >
            <div class="d-flex mx-2" :class="isMobSmallScreen ? 'mt-3' : ''">
              <div>{{ truncateText(stripHtmlTags(appliedTempName)) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div :class="{ container: isMobSmallScreen }">
    <div
      v-if="
        get(customizeCharacter, 'template_applied') !== get(route.query, 'tempId') &&
        !isEmpty(get(customizeCharacter, 'template_applied')) &&
        !isEmpty(get(route.query, 'tempId'))
      "
      :class="{ 'd-flex flex-column mt-2': !isMobSmallScreen, 'row px-1': isMobSmallScreen }"
    >
      <div class="temp-box w-100" :class="isMobSmallScreen ? ' col-6 pr-1' : 'd-flex flex-column'">
        <div v-if="!isMobSmallScreen" style="background: #eaecf6">
          <div class="mb-1">
            <ion-button class="px-2 w-100" style="height: 20px; --border-radius: 12px; text-transform: none">
              <ion-icon style="font-size: 16px" :icon="homeOutline" />
              <div class="mx-1" style="margin-top: 2px">In Use</div>
            </ion-button>
          </div>
        </div>

        <div class="d-flex" :class="isMobSmallScreen ? 'direct-row' : 'direct-col'">
          <div class="char-applied-bg d-flex flex-column px-2 w-100">
            <div
              class="character-profile-header position-relative mt-2 mb-2"
              :class="isMobSmallScreen ? '' : 'set-padding'"
            >
              <div class="cover-applied"></div>
              <div class="rest-applied position-relative py-1">
                <div class="d-flex flex-column align-items-center">
                  <ion-button class="px-2 mt-1 small-char-button"></ion-button>
                  <ion-button class="px-2 small-title-button"></ion-button>
                  <ion-button class="px-2 small-applied-button"></ion-button>
                </div>
              </div>
            </div>
            <div class="character-section position-relative mb-2" :class="isMobSmallScreen ? '' : 'set-padding'">
              <div class="resttitle-applied position-relative">
                <div class="d-flex flex-column py-1 align-items-center">
                  <ion-button class="px-2 mt-1 small-app-title-button"></ion-button>
                  <ion-button class="px-2 small-default-button"></ion-button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isMobSmallScreen"
            style="background: #c6c8d6"
            :class="isSmallMobScreen ? 'set-edit-width' : 'w-100'"
          >
            <ion-button
              class="mt-3 px-2 applied-btn"
              :class="isSmallMobScreen ? 'set-btn-width' : 'w-100'"
              style="
                --background: linear-gradient(
                  to right,
                  rgb(23 219 233 / 84%) 9%,
                  rgb(22 173 227 / 182%) 56%,
                  rgb(3 168 227) 98%,
                  rgb(3 168 227) 100%
                );
                --border-radius: 10px;
                height: 71px;
              "
              @click="openEdit"
              >Edit
            </ion-button>
          </div>
        </div>
        <div style="background: #eaecf6" :class="isMobSmallScreen ? '' : 'px-2 py-1'">
          <div class="d-flex" style="padding-bottom: 1px" :class="isMobSmallScreen ? 'set-width' : 'change-app-btn'">
            <ion-button v-if="isMobSmallScreen" class="px-2" style="height: 20px; --border-radius: 12px">
              <ion-icon style="font-size: 16px" :icon="homeOutline" />
              <div class="mx-1" style="margin-top: 2px">In Use</div>
            </ion-button>
            <ion-button
              v-else
              class="w-100 applied-btn mt-3"
              style="
                --background: linear-gradient(
                  to right,
                  rgb(23 219 233 / 84%) 9%,
                  rgb(22 173 227 / 182%) 56%,
                  rgb(3 168 227) 98%,
                  rgb(3 168 227) 100%
                );
              "
              @click="openEdit"
            >
              Edit
            </ion-button>
            <div class="mx-2 set-app-text-width" style="margin-top: 4px; color: black">
              {{ appliedTempName }}
            </div>
          </div>
        </div>
      </div>
      <div class="temp-box w-100" :class="isMobSmallScreen ? ' col-6 pl-1' : 'mt-3 d-flex flex-column'">
        <div v-if="!isMobSmallScreen" style="background: #eaecf6">
          <div class="mb-1 px-2">
            <ion-button
              class="w-100"
              style="--background: rgb(255 255 255); height: 20px; --border-radius: 12px; text-transform: none"
            >
              <div class="mx-1" style="color: var(--ion-color-primary); font-size: 16px; font-weight: bold">
                <i class="ti-pencil" />
              </div>
              <div class="text-primary" style="font-weight: bold">Trying on</div>
            </ion-button>
          </div>
        </div>
        <div class="d-flex" :class="isMobSmallScreen ? 'direct-row' : 'direct-col'">
          <div class="char-bg d-flex flex-column w-100">
            <div class="position-relative py-2" :class="isMobSmallScreen ? 'px-2' : 'set-padding'">
              <div v-if="!isEmpty(tempres) || !isEmpty(usedTemp)" class="position-absolute d-flex small-icon">
                <div v-for="(temp, index) of displayedChars" :key="index">
                  <img
                    :src="resizeUpload(get(temp, 'info.cropProfilePicture', '/empty.png'), '80x80')"
                    class="icon-img"
                  />
                </div>
                <div v-if="hiddenChars?.length >= 1">
                  <small class="small-text">({{ hiddenChars?.length }}+)</small>
                </div>
              </div>
              <div class="character-header">
                <div class="cover"></div>
                <div class="rest-top position-relative py-1">
                  <div class="d-flex flex-column align-items-center">
                    <ion-button class="px-2 mt-1 small-temp-char-button"></ion-button>
                    <ion-button class="px-2 small-temp-quote-button"></ion-button>
                    <ion-button class="px-2 small-button"></ion-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="character-infosection position-relative" :class="{ 'set-padding mb-2': !isMobSmallScreen }">
              <div class="rest-section position-relative">
                <div class="d-flex flex-column py-1 align-items-center">
                  <ion-button class="px-2 mt-1 small-title-button"></ion-button>
                  <ion-button class="px-2 small-temp-default-button"></ion-button>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="isMobSmallScreen"
            style="background: rgb(185, 185, 191)"
            :class="isSmallMobScreen ? 'set-edit-width' : 'w-100'"
          >
            <div class="d-flex flex-column px-2 py-2" style="margin-top: 3px">
              <ion-button
                class="applied-btn"
                :disabled="isSaving"
                style="
                  --background: #0088a5;
                  --border-radius: 10px;
                  --background: linear-gradient(
                    to right,
                    rgb(185 216 68) 4%,
                    rgb(92 187 80) 66%,
                    rgb(64 179 102) 98%,
                    rgb(24 165 97) 100%
                  );
                "
                @click="saveTemplate('save')"
              >
                <ChLoading size="sm" v-if="isSaving" class="spinner" />
                <ion-icon v-else class="button-icon" :icon="saveOutline" />
              </ion-button>
              <ion-button
                class="applied-btn"
                :disabled="isApplying"
                style="
                  --background: linear-gradient(
                    to right,
                    rgb(219 49 249) 7%,
                    rgb(176 75 216) 73%,
                    rgb(149 56 200) 98%,
                    rgb(143 49 199) 100%
                  );
                  --border-radius: 10px;
                "
                @click="applyDesign('use')"
              >
                <ChLoading size="sm" v-if="isApplying" class="spinner" />
                <span v-else>Use</span>
              </ion-button>
            </div>
          </div>
        </div>
        <div style="background: #eaecf6">
          <div
            class="d-flex"
            :class="isMobSmallScreen ? 'set-width' : 'change-app-btn px-2 mb-1'"
            style="padding-bottom: 1px"
          >
            <ion-button
              v-if="isMobSmallScreen"
              class="px-2"
              style="--background: rgb(255 255 255); height: 20px; --border-radius: 12px; text-transform: none"
            >
              <div class="mx-1" style="color: var(--ion-color-primary); font-size: 16px">
                <i class="ti-pencil" />
              </div>
              <div class="text-primary">Trying on</div>
            </ion-button>
            <div v-else class="d-flex mt-3">
              <ion-button
                :disabled="isSaving"
                class="w-100 applied-btn"
                style="
                  --background: linear-gradient(
                    to right,
                    rgb(185 216 68) 4%,
                    rgb(92 187 80) 66%,
                    rgb(64 179 102) 98%,
                    rgb(24 165 97) 100%
                  );
                  --border-radius: 10px;
                "
                @click="saveTemplate('save')"
              >
                <ChLoading size="sm" v-if="isSaving" class="spinner" />
                <ion-icon v-else class="button-icon" :icon="saveOutline" />
              </ion-button>
              <ion-button
                class="w-100 applied-btn"
                :disabled="isApplying"
                style="
                  --border-radius: 10px;
                  --background: linear-gradient(
                    to right,
                    rgb(219 49 249) 7%,
                    rgb(176 75 216) 73%,
                    rgb(149 56 200) 98%,
                    rgb(143 49 199) 100%
                  );
                "
                @click="applyDesign('use')"
              >
                <ChLoading size="sm" v-if="isApplying" class="spinner" />
                <span v-else>Use</span>
              </ion-button>
            </div>
            <div class="mx-2 set-text-width" style="margin-top: 4px; color: black">
              {{
                !isEmpty(get(currentTemplate, 'name'))
                  ? truncateText(stripHtmlTags(currentTemplate.name), 18)
                  : truncateText(stripHtmlTags(appliedTempName), 18)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <template-design-updated-modal
    :is-open="isUpdateCharModal"
    :template-name="updatedTempName"
    :used="isUsed"
    :is-template-empty="isTemplateEmpty"
    @closeOk="closeOkUpdatedModal"
    @closeSavedModal="closeSavedModal"
    @close-undo="closeUndoModal"
    @dismiss-modal="closeUpdatedModal"
  />
  <switching-template-design-modal
    :is-open="isSwitchingModal"
    :template-id="get(currentTemplate, 'id')"
    :template-name="get(currentTemplate, 'name')"
    :customize="customizeCharacter"
    :current-customize-data="currentTemplate"
    @close="closeSwitchModal"
  />
</template>

<script lang="ts" setup>
import { arrowBackOutline, homeOutline, pencilOutline, saveOutline, swapHorizontalOutline } from 'ionicons/icons';
import { stripHtmlTags } from '@/shared/utils/string';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import TemplateDesignUpdatedModal from '@/shared/modals/TemplateDesignUpdatedModal.vue';
import SwitchingTemplateDesignModal from '@/shared/modals/SwitchingTemplateDesignModal.vue';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { textColorChanged } from '@/shared/utils/textConverter';
import {
  applyCharacterStylizeTemplate,
  getTemplateUsedByOtherChar,
  updateCharTemplate,
} from '@/shared/actions/characters';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';

const { isCustomizationSize } = useWindowSize();
const {
  currentTemplate,
  customizeCharacter,
  template,
  loadTemplate,
  loadCharacterTemplate,
  previousCustomizeandTempData,
  previousTemplateData,
} = characterStylizeStore();

const props = defineProps({
  customize: {
    type: Object,
    default: {},
  },
  usedTemp: {
    type: Array,
    default: [],
  },
});
const customize = toRef(props, 'customize');
const isModalOpen = ref(false);
const isTemplateEmpty = ref(false);
const isSaving = ref(false);
const isApplying = ref(false);
const isSwitchingModal = ref(false);
const isSwitch = ref(false);
const tempres = ref([]);
const isUpdateCharModal = ref(false);
const isUsed = ref(false);
const isApply = ref('');
const istoast = ref(false);
const counter = ref(1);
const route = useRoute();
const windowWidth = ref(window.innerWidth);
const router = useRouter();
const templateIdData = ref({});
const usedTemp = toRef(props, 'usedTemp');
const {
  updatePreviousTemplate,
  updateUserTemplateReuserBtn,
  user: loggedInUser,
  updatePreviousCustomize,
} = authStore();

const emits = defineEmits(['showtoast', 'applied', 'dismiss']);

const appliedTempName = computed(() => {
  const temp = template.value?.find((temp: any) => temp.id === get(customizeCharacter.value, 'template_applied'));

  const name = !isEmpty(temp) ? temp?.name : get(currentTemplate.value, 'name');
  return name;
});

const updatedTempName = computed(() => {
  const temp = template.value?.find((temp: any) => temp.id === get(customizeCharacter.value, 'template_applied'));
  const name = isEmpty(get(route.query, 'tempId')) ? get(temp, 'name') : get(currentTemplate.value, 'name');
  return name;
});

const topsectionBorder = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.topsection.border');
});
const infosectionBorder = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.infoSections.border');
});

const appliedTopBorder = computed(() => {
  return get(customizeCharacter.value, 'data.theme.styles.topsection.border');
});

const appliedInfoSectionBorder = computed(() => {
  return get(customizeCharacter.value, 'data.theme.styles.infoSections.border');
});

const appliedsectionColor = computed(() => {
  return get(customizeCharacter.value, 'data.theme.styles.topsection.color');
});
const appliedInfosectionColor = computed(() => {
  return get(customizeCharacter.value, 'data.theme.styles.infoSections.color');
});
const topsectionColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.topsection.color');
});
const infosectionColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.infoSections.color');
});

const topsectionShadow = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.topsection.shadow') === 'none'
    ? '0px 0px 0px'
    : get(currentTemplate.value, 'data.theme.styles.topsection.shadow') === 'alittle'
    ? '0 2px 13px rgb(0 0 0 / 15%)'
    : '3px 2px 20px #635d5d';
});
const infosectionShadow = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.infoSections.shadow') === 'none'
    ? '0px 0px 0px'
    : get(currentTemplate.value, 'data.theme.styles.infoSections.shadow') === 'alittle'
    ? '0 2px 13px rgb(0 0 0 / 15%)'
    : '3px 2px 20px #635d5d';
});
const appliedTopShadow = computed(() => {
  return get(customizeCharacter.value, 'data.theme.styles.topsection.shadow') === 'none'
    ? '0px 0px 0px'
    : get(customizeCharacter.value, 'data.theme.styles.topsection.shadow') === 'alittle'
    ? '0 2px 13px rgb(0 0 0 / 15%)'
    : '3px 2px 20px #635d5d';
});
const appliedInfoShadow = computed(() => {
  return get(customizeCharacter.value, 'data.theme.styles.infoSections.shadow') === 'none'
    ? '0px 0px 0px'
    : get(customizeCharacter.value, 'data.theme.styles.infoSections.shadow') === 'alittle'
    ? '0 2px 13px rgb(0 0 0 / 15%)'
    : '3px 2px 20px #635d5d';
});

const topsectionEdges = computed(() => {
  if (get(currentTemplate.value, 'data.theme.styles.topsection.edges') === 'sharp') return '0px';
  else if (get(currentTemplate.value, 'data.theme.styles.topsection.edges') === 'round') return '10px';
  return '20px';
});
const infosectionEdges = computed(() => {
  if (get(currentTemplate.value, 'data.theme.styles.sectionEdges') === 'sharp') return '0px';
  else if (get(currentTemplate.value, 'data.theme.styles.styles.sectionEdges') === 'round') return '10px';
  return '20px';
});
const appliedsectionEdges = computed(() => {
  if (get(customizeCharacter.value, 'data.theme.styles.topsection.edges') === 'sharp') return '0px';
  else if (get(customizeCharacter.value, 'data.theme.styles.topsection.edges') === 'round') return '10px';
  return '20px';
});
const appliedInfosectionEdges = computed(() => {
  if (get(customizeCharacter.value, 'data.theme.styles.sectionEdges') === 'sharp') return '0px';
  else if (get(customizeCharacter.value, 'data.theme.styles.sectionEdges') === 'round') return '10px';
  return '20px';
});
const getButtonsColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.backgrounds.buttons');
});
const sectionColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.backgrounds.infoSection');
});
const getButtonsAppliedColor = computed(() => {
  return get(customizeCharacter.value, 'data.theme.backgrounds.buttons');
});

const getCharColor = computed(() => {
  return get(customizeCharacter.value, 'data.theme.text.charName.color');
});
const getQuoteColor = computed(() => {
  return get(customizeCharacter.value, 'data.theme.text.quote.color');
});
const getTempCharColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.charName.color');
});
const getTempQuoteColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.quote.color');
});

const getUsedCharactersInTemplates = async () => {
  if (get(route.query, 'tempId')) {
    const tId = get(route.query, 'tempId');
    const res = await getTemplateUsedByOtherChar(tId as string);
    tempres.value = res.results;
  }
};
const displayedChars = computed(() => {
  if (!isEmpty(tempres.value)) {
    return tempres.value.slice(0, 3);
  } else if (!isEmpty(usedTemp.value)) {
    return usedTemp.value.slice(0, 3);
  }
});
const hiddenChars = computed(() => {
  if (tempres.value.length >= 3 && !isEmpty(tempres.value)) return tempres.value.slice(3);
  else if (usedTemp.value.length >= 3 && !isEmpty(usedTemp.value)) return usedTemp.value.slice(3);
});

const sectionAppliedColor = computed(() => {
  return get(customizeCharacter.value, 'data.theme.backgrounds.infoSection');
});

const setTopSectionBackground = computed(() => {
  return get(currentTemplate.value, 'data.theme.backgrounds.topSection');
});
const sectionAppTitleColor = computed(() => {
  return get(customizeCharacter.value, 'data.theme.text.titles.color');
});

const textConverter = computed(() => {
  return getButtonsColor.value ? textColorChanged(getButtonsColor.value) : '#ae38e5';
});
const sectionTitleColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.titles.color');
});
const sectionTextColor = computed(() => {
  return get(customizeCharacter.value, 'data.theme.text.default.color');
});
const defaultColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.text.default.color');
});
const setTopAppliedbg = computed(() => {
  return get(customizeCharacter.value, 'data.theme.backgrounds.topSection');
});
const isMobSmallScreen = computed(() => {
  return windowWidth.value < 1365;
});
const isSmallMobScreen = computed(() => {
  return windowWidth.value <= 350;
});
const istruncateSmlScreen = computed(() => {
  return windowWidth.value <= 360;
});
const handleResize = () => {
  windowWidth.value = window.innerWidth;
};
watch(currentTemplate, async () => {
  if (get(route.query, 'tempId') && !isEmpty(currentTemplate.value)) {
    templateIdData.value = cloneDeep(currentTemplate.value?.data);
  }
});

onMounted(async () => {
  if (!isCustomizationSize.value)
    document.getElementById('scroll')?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  getUsedCharactersInTemplates();
  window.addEventListener('resize', handleResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
const openModel = (switchVal: any) => {
  isModalOpen.value = true;
  isSwitch.value = switchVal;
};
const currentCounter = computed(() => {
  const { counter } = authStore();
  return counter.value;
});
const saveTemplate = async (value: any) => {
  isSaving.value = true;
  try {
    const payload = {
      data: currentTemplate.value?.data,
    };
    const temp = {
      data: templateIdData.value,
    };
    if (value === 'save') {
      updatePreviousTemplate({
        previousTempData: temp,
      });
    }

    const temp_id = !isEmpty(get(currentTemplate.value, 'id'))
      ? currentTemplate.value.id
      : customizeCharacter.value?.template_applied;
    await updateCharTemplate(temp_id, true, payload);

    if (value === 'undo') {
      await toast.show('Template reverted successfully', 'nonative', 'success');
    } else if (value === 'save') {
      isUpdateCharModal.value = true;
      if (isUsed.value) isUsed.value = false;
    }
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
  }
  isSaving.value = false;
};

const getTemplateDetails = computed(() => {
  if (!isEmpty(template.value)) {
    return get(customizeCharacter.value, 'template_applied') === get(route.query, 'tempId')
      ? true
      : get(customizeCharacter.value, 'template_applied') !== get(route.query, 'tempId') &&
        !isEmpty(get(customizeCharacter.value, 'template_applied')) &&
        !isEmpty(get(route.query, 'tempId'))
      ? false
      : isEmpty(get(customizeCharacter.value, 'template_applied')) && get(route.query, 'tempId')
      ? true
      : !isEmpty(get(customizeCharacter.value, 'template_applied'));
  }
});

const openEditRoute = async () => {
  const tempId = !isEmpty(get(customizeCharacter.value, 'template_applied'))
    ? get(customizeCharacter.value, 'template_applied')
    : get(route.query, 'tempId');

  !isEmpty(get(customizeCharacter.value, 'template_applied'))
    ? router.replace({
        name: 'character-stylize',
        params: { id: get(customizeCharacter.value, 'entity_id') },
        query: { tab: 'theme' },
      })
    : router.replace({
        name: 'character-stylize',
        params: { id: get(customizeCharacter.value, 'entity_id') },
        query: { tempId: get(route.query, 'tempId'), tab: 'theme' },
      });
  await loadTemplate(tempId as string);
};
const openEdit = async () => {
  if (JSON.stringify(get(currentTemplate.value, 'data.theme')) !== JSON.stringify(get(templateIdData.value, 'theme'))) {
    isSwitchingModal.value = true;
    return;
  }

  openEditRoute();
};
const closeSwitchModal = async () => {
  isSwitchingModal.value = false;
  openEditRoute();
  loadCharacterTemplate();
};
const applyDesign = async (value: any) => {
  isApplying.value = true;
  try {
    const temp_id = !isEmpty(get(currentTemplate.value, 'id'))
      ? currentTemplate.value!.id
      : customizeCharacter.value?.template_applied;
    const prevSelctedTemp = template.value?.find((temp: any) => temp.id === temp_id);
    if (
      JSON.stringify(get(currentTemplate.value, 'data.theme')) === JSON.stringify(get(prevSelctedTemp, 'data.theme'))
    ) {
      currentCounter.value >= 3 ? '' : (counter.value += currentCounter.value);
      updateUserTemplateReuserBtn({
        data: { tmp_quickapply: true },
        user: loggedInUser.value,
        counter: counter.value,
      });
    }
    if (!isEmpty(template.value)) {
      const id = !isEmpty(get(currentTemplate.value, 'id'))
        ? currentTemplate.value.id
        : customizeCharacter.value?.template_applied;
      const payload = {
        entity_id: customizeCharacter.value?.entity_id,
        entity_type: get(customizeCharacter.value, 'entity.type'),
        data: currentTemplate.value.data,
        applied_template: id,
      };
      let prevtemp: any;
      let prevcust: any;
      if (
        isEmpty(template.value) ||
        get(customizeCharacter.value, 'template_applied') ||
        (get(customizeCharacter.value, 'template_applied') && get(route.query, 'tempId')) ||
        isEmpty(get(customizeCharacter.value, 'template_applied'))
      ) {
        prevcust = {
          data: customizeCharacter.value?.data,
        };
      } else {
        prevcust = {
          data: templateIdData.value,
        };
      }

      if (get(route.query, 'tempId')) {
        prevtemp = {
          data: templateIdData.value,
        };
      } else {
        prevtemp = {
          data: customizeCharacter.value?.data,
        };
      }

      if (isEmpty(get(customizeCharacter.value, 'template_applied')) && get(route.query, 'tempId'))
        isTemplateEmpty.value = true;

      updatePreviousTemplate({
        previousTempData: prevtemp,
      });
      updatePreviousCustomize({
        previousCustomizeData: prevcust,
        isUndo: false,
      });
      await applyCharacterStylizeTemplate(payload);
      saveTemplate(value);
    }

    isUpdateCharModal.value = true;
    isUsed.value = true;
    isApply.value = value;
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
  }
  isApplying.value = false;
};
const setBackgroundColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.backgrounds.page');
});

const appliedbgColor = computed(() => {
  return get(customizeCharacter.value, 'data.theme.backgrounds.page');
});

const closeModal = () => {
  isUpdateCharModal.value = false;
  setTimeout(() => {
    isUsed.value = false;
  }, 500);
};

const closeOkUpdatedModal = async () => {
  closeModal();
  const tempId = get(route.query, 'tempId');
  await loadTemplate(tempId as string);
};

const closeUndoModal = async (value: any) => {
  closeModal();
  if (!value) {
    await previousTemplateData();
    saveTemplate('undo');
  } else if (value) {
    previousCustomizeandTempData();
  }

  updatePreviousCustomize({
    isUndo: false,
    isTempEmp: false,
  });

  await toast.show('Template reverted successfully', 'nonative', 'success');
};
const closeSavedModal = async () => {
  closeOkBtnModal();
  //  closeUpdatedModal();
};

const closeOkBtnModal = async () => {
  if (get(route.query, 'tempId') && isApply.value === 'apply') {
    const temp = {
      data: customizeCharacter.value?.data,
    };
    updatePreviousCustomize({
      previousCustomizeData: temp,
      isUndo: false,
    });
    updatePreviousTemplate({
      previousTempData: temp,
    });
  }
  closeModal();
  emits('showtoast');
};
const closeUpdatedModal = async () => {
  closeModal();
  emits('dismiss');
  route.name === 'character-stylize'
    ? router.push({ name: 'character-profile-new', params: { slug: get(customizeCharacter.value, 'entity.slug') } })
    : router.push({
        name: 'character-profile-draft-new',
        params: { id: get(customizeCharacter.value, 'entity_id') },
      });
};
</script>

<style scoped lang="sass">
.set-app-text-width
  width: 250px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  @media(max-width:495px)
    width: calc(100% - 10px) !important
.set-text-width
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  @media(min-width:1460px)
    width: 250px
  @media(max-width:1459px) and (min-width:560px)
    width: 95px
  @media(max-width:560px)
    width: 175px
.icon-img
  width: 30px
  height: 30px
  border-radius: 18px
  @media(max-width:1460px)
    height: 22px
    width: 22px
.container
  // max-width: 499px
  margin: 0 auto
.change-app-btn
  flex-direction: column-reverse
.set-padding
  max-width: 80px !important
  width: 80px !important
.direct-col
  flex-direction: column
.direct-row
  min-height: 112px !important
  flex-direction: row
.applied-btn
  --background: linear-gradient(to right ,rgb(189 71 216) 21%,rgb(108 83 206) 73%,rgb(94 93 206) 98%,rgb(86 98 206) 100%)
  opacity: 1
  height: 44px
  --border-radius: 14px
  animation: gradient 4s ease infinite
  transition: transform 0.2s
  @media(max-width:1460px)
    height: 33px
.applied-btn:after
  content: ""
  position: absolute
  top: 4px
  left: 4px
  width: calc(100% - 8px)
  height: 50%
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))
  border-radius: 10px
@keyframes gradient
  50%
    background-position: 140% 50%
    transform: skew(-2deg)

.small-title-button
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(sectionTitleColor)
  overflow: hidden
.small-app-title-button
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(sectionAppTitleColor)
  overflow: hidden
.small-icon
  left: -84px
  top: 3px
  gap: 3px
  z-index: 2
  @media(max-width:1460px)
    left: 1px
    top: 1px
    gap: 2px
.small-text
  position: absolute
  top: 5px
  left: 98px
  color: v-bind(textConverter) !important
  @media(max-width:1460px)
    left: 67px
    top: 1px
    color: black !important
.small-default-button
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(sectionTextColor)
  overflow: hidden
.small-temp-default-button
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(defaultColor)
.set-edit-width
  @media(max-width:330px)
    max-width: calc(100% - 73px)

.set-btn-width
  @media(max-width:330px)
    width: 64px !important
  @media(max-width:305px)
    width: 62px !important
  @media(max-width:297px)
    width: 58px !important
  @media(max-width:295px)
    width: 43px !important

.set-width
  @media(max-width:560px)
    flex-direction: column

.char-bg
  background: v-bind(setBackgroundColor)
  align-items: center
.char-applied-bg
  background: v-bind(appliedbgColor)
  align-items: center

.text
  color: black
  padding-top: 1px
.dark .text
  color: black
.icon
  font-size: 14px
.button-icon
  font-size: 18px

.dark .edit-text
  color: black !important

.character-header
  overflow: hidden
  border: 3px v-bind('`${topsectionBorder} ${topsectionColor}`')
  box-shadow: v-bind(topsectionShadow)
  border-radius: v-bind(topsectionEdges)
.character-infosection
  overflow: hidden
  border: 3px v-bind('`${infosectionBorder} ${infosectionColor}`')
  box-shadow: v-bind(infosectionShadow)
  border-radius: v-bind(infosectionEdges)

.character-profile-header
  overflow: hidden
  border: 3px v-bind('`${appliedTopBorder} ${appliedsectionColor}`')

  box-shadow: v-bind(appliedTopShadow)
  border-radius: v-bind(appliedsectionEdges)
.character-section
  overflow: hidden
  border: 3px v-bind('`${appliedInfoSectionBorder} ${appliedInfosectionColor}`')
  box-shadow: v-bind(appliedInfoShadow)
  border-radius: v-bind(appliedInfosectionEdges)
.cover
  height: 13px
  overflow: hidden

.cover-applied
  height: 13px
  overflow: hidden
  background-color: v-bind(getButtonsAppliedColor)
.rest-top
  text-align: center
  background: v-bind(setTopSectionBackground)
.rest-section
  background: v-bind(sectionColor)

.rest-applied
  background: v-bind(setTopAppliedbg)
.resttitle-applied
  background: v-bind(sectionAppliedColor)
.cover
  background-color: v-bind(getButtonsColor)

.cover-applied
  background-color: v-bind(getButtonsAppliedColor)
.templates
  height: 194px
  position: fixed
  background: white
  top: 0
  width: 50%
  z-index: 1
  border-bottom-left-radius: 15px
  border-bottom-right-radius: 15px
.small-button
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(getButtonsColor)
  overflow: hidden
.small-applied-button
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(getButtonsAppliedColor)
  overflow: hidden
.small-char-button
  overflow: hidden
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(getCharColor)
.small-temp-char-button
  overflow: hidden
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(getTempCharColor)

.small-temp-quote-button
  overflow: hidden
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(getTempQuoteColor)
.small-quote-button
  height: 4px
  --border-radius: 0px
  margin-top: 0px
  --background: v-bind(getQuoteColor)
  overflow: hidden
.temp-box
  border-radius: 10px
  overflow: hidden
  @media(max-width:1365px)
    width: calc(59% - 23px) !important
    height: 40px
    overflow: unset
</style>
