<template>
  <div
    class="character-customize-key-info position-relative mx-3 px-1 py-3"
    style="background: #41476c !important; color: white !important"
  >
    <div class="d-flex justify-content-center" style="font-weight: bold; font-size: 22px">Welcome to Themes!</div>
    <div class="d-flex mt-3">
      <inline-svg src="/palette.svg" width="18px" height="22px" class="mx-2" />
      <div>
        Customize Colors in <strong v-if="isMobSmallScreen" class="mr-1">Background Colors!</strong>
        <strong v-else class="mr-1">Backgrounds!</strong>
      </div>
    </div>
    <div class="d-flex justify-content-start mt-2">
      <inline-svg src="/text.svg" width="18px" height="22px" class="mx-2" />
      <div class="d-flex">Change Fonts in<strong class="mx-1"> Text!</strong></div>
    </div>
    <div class="d-flex justify-content-start mt-2">
      <inline-svg src="/hammer-tool.svg" width="18px" height="22px" class="mx-2" />
      <div class="d-flex">Modify Sections in <strong class="mx-1">Styles!</strong></div>
    </div>
  </div>
  <br />
  <div class="character-customize-key-info position-relative mx-3 pt-2">
    <theme-customize-background @click="actionClicked" />
  </div>
  <div class="character-customize-key-info position-relative mx-3 mt-3 pt-2">
    <theme-text :character="character" @click="actionClicked" />
  </div>
  <div class="character-customize-key-info position-relative mx-3 mt-3 pt-2 pb-3">
    <theme-styles />
  </div>
</template>
<script lang="ts" setup>
import { colorPaletteOutline, textOutline } from 'ionicons/icons';
import ThemeCustomizeBackground from './theme-customize-background.vue';
import ThemeText from './theme-text-section.vue';
import ThemeStyles from './theme-styles.vue';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';
const { character } = characterProfileStore();

const windowWidth = ref(window.innerWidth);
const emits = defineEmits(['click']);

const route = useRoute();
const actionClicked = (select: any) => {
  emits('click', select);
};
onMounted(async () => {
  window.addEventListener('resize', handleResize);
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 1460;
});

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};
</script>

<style scoped lang="sass">

.character-customize-key-info
  background: #eaecf6
  border-radius: 10px
  @media(max-width:1460px)
    margin-bottom: 15px
</style>
