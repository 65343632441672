<template>
  <div class="mx-2">
    <div class="title">Styles:</div>

    <div style="background: white; border-radius: 10px" class="px-2 py-2 mt-2">
      <div class="mt-2 d-flex">
        <div><ion-icon class="icon" style="font-size: 26px; color: #17074c" :icon="personCircle" /></div>
        <div class="sub-title mx-1" style="margin-top: 3px">Profile Pic:</div>
      </div>
      <div>
        <div class="text mt-1">Border:</div>
        <div class="temp-row" style="margin-top: 2px">
          <div
            class="small-button w-100 clickable-item"
            :class="profileBorder === 'none' ? 'selected-border' : 'set-border'"
            @click="changedStyles('profilePic', 'border', 'none')"
          >
            <div class="text-center d-flex justify-content-center mt-1">None</div>
          </div>

          <div
            class="small-button border-middle-btn clickable-item"
            :class="profileBorder === 'solid' ? 'selected-border' : 'set-border'"
            @click="changedStyles('profilePic', 'border', 'solid')"
          >
            <div class="text-center d-flex justify-content-center mt-1">Solid</div>
          </div>
          <div
            class="w-100 small-button border-last-btn clickable-item"
            style="border-style: dotted"
            :class="profileBorder === 'dotted' ? 'selected-border' : 'set-border'"
            @click="changedStyles('profilePic', 'border', 'dotted')"
          >
            <div class="text-center d-flex justify-content-center" style="margin-top: 2px">Dotted</div>
          </div>
          <div
            class="w-100 small-button border-last-btn clickable-item"
            style="border-style: dashed; margin-right: 4px"
            :class="profileBorder === 'dashed' ? 'selected-border' : 'set-border'"
            @click="changedStyles('profilePic', 'border', 'dashed')"
          >
            <div class="text-center d-flex justify-content-center" style="margin-top: 2px">Dashed</div>
          </div>
        </div>
        <div class="d-flex mt-3">
          <div class="text" style="margin-top: -2px">Border Color:</div>
          <div class="ml-auto mx-1">
            <color-circle-box
              :value="profileColor"
              @changed="(color) => changedStyles('profilePic', 'color', color)"
              @select="actionClicked"
            />
          </div>
        </div>

        <div class="text" style="margin-top: -15px">Shadow:</div>
        <div class="temp-row">
          <div
            class="small-button w-100 clickable-item"
            :class="profileShadow === 'none' ? 'selected-shadow' : 'set-shadow'"
            @click="changedStyles('profilePic', 'shadow', 'none')"
          >
            <div class="text-center d-flex justify-content-center mt-1">None</div>
          </div>

          <div
            class="small-button middle-btn clickable-item"
            :class="profileShadow === 'alittle' ? 'selected-shadow' : 'set-shadow'"
            @click="changedStyles('profilePic', 'shadow', 'alittle')"
          >
            <div class="text-center d-flex justify-content-center mt-1">A little</div>
          </div>
          <div
            class="w-100 small-button last-btn clickable-item"
            :class="profileShadow === 'alot' ? 'selected-shadow' : 'set-shadow'"
            @click="changedStyles('profilePic', 'shadow', 'alot')"
          >
            <div class="text-center d-flex justify-content-center mt-1">A lot</div>
          </div>
        </div>
      </div>
    </div>
    <div style="background: white; border-radius: 10px" class="px-2 py-2 mt-2">
      <div class="mt-2 d-flex">
        <div><ion-icon class="icon" style="font-size: 26px; color: #17074c" :icon="scaleOutline" /></div>
        <div class="sub-title mx-1" style="margin-top: 3px">Top Section:</div>
      </div>
      <div>
        <div class="text mt-1">Border:</div>
        <div class="temp-row" style="margin-top: 2px">
          <div
            class="small-button w-100 clickable-item"
            :class="topBorder === 'none' ? 'selected-border' : 'set-border'"
            @click="changedStyles('topsection', 'border', 'none')"
          >
            <div class="text-center d-flex justify-content-center mt-1">None</div>
          </div>

          <div
            class="small-button border-middle-btn clickable-item"
            :class="topBorder === 'solid' ? 'selected-border' : 'set-border'"
            @click="changedStyles('topsection', 'border', 'solid')"
          >
            <div class="text-center d-flex justify-content-center mt-1">Solid</div>
          </div>
          <div
            class="w-100 small-button border-last-btn clickable-item"
            style="border-style: dotted"
            :class="topBorder === 'dotted' ? 'selected-border' : 'set-border'"
            @click="changedStyles('topsection', 'border', 'dotted')"
          >
            <div class="text-center d-flex justify-content-center" style="margin-top: 2px">Dotted</div>
          </div>
          <div
            class="w-100 small-button border-last-btn clickable-item"
            style="border-style: dashed; margin-right: 4px"
            :class="topBorder === 'dashed' ? 'selected-border' : 'set-border'"
            @click="changedStyles('topsection', 'border', 'dashed')"
          >
            <div class="text-center d-flex justify-content-center" style="margin-top: 2px">Dashed</div>
          </div>
        </div>

        <div class="d-flex mt-3">
          <div class="text" style="margin-top: -2px">Border Color:</div>
          <div class="ml-auto mx-1">
            <color-circle-box
              :value="topSectionColor"
              @changed="(color) => changedStyles('topsection', 'color', color)"
              @select="actionClicked"
            />
          </div>
        </div>

        <div class="text" style="margin-top: -15px">Shadow:</div>
        <div class="temp-row">
          <div
            class="small-button w-100 clickable-item"
            :class="topSectionShadow === 'none' ? 'selected-shadow' : 'set-shadow'"
            @click="changedStyles('topsection', 'shadow', 'none')"
          >
            <div class="text-center d-flex justify-content-center mt-1">None</div>
          </div>

          <div
            class="small-button middle-btn clickable-item"
            :class="topSectionShadow === 'alittle' ? 'selected-shadow' : 'set-shadow'"
            @click="changedStyles('topsection', 'shadow', 'alittle')"
          >
            <div class="text-center d-flex justify-content-center mt-1">A little</div>
          </div>
          <div
            class="w-100 small-button last-btn clickable-item"
            :class="topSectionShadow === 'alot' ? 'selected-shadow' : 'set-shadow'"
            @click="changedStyles('topsection', 'shadow', 'alot')"
          >
            <div class="text-center d-flex justify-content-center mt-1">A lot</div>
          </div>
        </div>

        <div class="text mt-2">Edges:</div>

        <div class="temp-row">
          <div
            class="small-button w-100 clickable-item"
            :class="topSectionEdges === 'sharp' ? 'selected-edges' : 'set-edges'"
            style="border-radius: 0px"
            @click="selectedTopSectionEdge('sharp')"
          >
            <div class="text-center d-flex justify-content-center mt-1">Sharp</div>
          </div>

          <div
            class="small-button middle-btn clickable-item"
            :class="topSectionEdges === 'round' ? 'selected-edges' : 'set-edges'"
            style="border-radius: 6px"
            @click="selectedTopSectionEdge('round')"
          >
            <div class="text-center d-flex justify-content-center mt-1">Round</div>
          </div>
          <div
            class="w-100 small-button last-btn clickable-item"
            :class="topSectionEdges === 'veryround' ? 'selected-edges' : 'set-edges'"
            style="border-radius: 20px"
            @click="selectedTopSectionEdge('veryround')"
          >
            <div class="text-center d-flex justify-content-center mt-1">Very Round</div>
          </div>
        </div>
      </div>
    </div>
    <div style="background: white; border-radius: 10px" class="px-2 py-2 mt-2">
      <div class="d-flex">
        <div><ion-icon class="icon" style="font-size: 26px; color: #17074c" :icon="readerOutline" /></div>
        <div class="sub-title" style="margin-top: 3px">Info Sections:</div>
      </div>
      <div>
        <div class="text mt-1">Border:</div>
        <div class="temp-row">
          <div
            class="small-button w-100 clickable-item"
            :class="sectionBorder === 'none' ? 'selected-border' : 'set-border'"
            @click="changedStyles('infoSections', 'border', 'none')"
          >
            <div class="text-center d-flex justify-content-center mt-1">None</div>
          </div>

          <div
            class="small-button border-middle-btn clickable-item"
            :class="sectionBorder === 'solid' ? 'selected-border' : 'set-border'"
            @click="changedStyles('infoSections', 'border', 'solid')"
          >
            <div class="text-center d-flex justify-content-center mt-1">Solid</div>
          </div>
          <div
            class="w-100 small-button border-last-btn clickable-item"
            style="border-style: dotted"
            :class="sectionBorder === 'dotted' ? 'selected-border' : 'set-border'"
            @click="changedStyles('infoSections', 'border', 'dotted')"
          >
            <div class="text-center d-flex justify-content-center" style="margin-top: 2px">Dotted</div>
          </div>
          <div
            class="w-100 small-button border-last-btn clickable-item"
            style="border-style: dashed; margin-right: 4px"
            :class="sectionBorder === 'dashed' ? 'selected-border' : 'set-border'"
            @click="changedStyles('infoSections', 'border', 'dashed')"
          >
            <div class="text-center d-flex justify-content-center" style="margin-top: 2px">Dashed</div>
          </div>
        </div>

        <div class="d-flex mt-3">
          <div class="text" style="margin-top: -2px">Border Color:</div>
          <div class="ml-auto mx-1">
            <color-circle-box
              :value="infoSectionCOlor"
              @changed="(color) => changedStyles('infoSections', 'color', color)"
              @select="actionClicked"
            />
          </div>
        </div>

        <div class="text" style="margin-top: -15px">Shadow:</div>
        <div class="temp-row">
          <div
            class="small-button w-100 clickable-item"
            :class="sectionShadow === 'none' ? 'selected-shadow' : 'set-shadow'"
            @click="changedStyles('infoSections', 'shadow', 'none')"
          >
            <div class="text-center d-flex justify-content-center mt-1">None</div>
          </div>

          <div
            class="small-button middle-btn clickable-item"
            :class="sectionShadow === 'alittle' ? 'selected-shadow' : 'set-shadow'"
            @click="changedStyles('infoSections', 'shadow', 'alittle')"
          >
            <div class="text-center d-flex justify-content-center mt-1">A little</div>
          </div>
          <div
            class="w-100 small-button last-btn clickable-item"
            :class="sectionShadow === 'alot' ? 'selected-shadow' : 'set-shadow'"
            @click="changedStyles('infoSections', 'shadow', 'alot')"
          >
            <div class="text-center d-flex justify-content-center mt-1">A lot</div>
          </div>
        </div>
        <div class="text mt-2">Edges:</div>

        <div class="temp-row">
          <div
            class="small-button w-100 clickable-item"
            :class="sectionEdges === 'sharp' ? 'selected-edges' : 'set-edges'"
            style="border-radius: 0px"
            @click="selectedValue('sharp')"
          >
            <div class="text-center d-flex justify-content-center mt-1">Sharp</div>
          </div>

          <div
            class="small-button middle-btn clickable-item"
            :class="sectionEdges === 'round' ? 'selected-edges' : 'set-edges'"
            style="border-radius: 6px"
            @click="selectedValue('round')"
          >
            <div class="text-center d-flex justify-content-center mt-1">Round</div>
          </div>
          <div
            class="w-100 small-button last-btn clickable-item"
            :class="sectionEdges === 'veryround' ? 'selected-edges' : 'set-edges'"
            style="border-radius: 20px"
            @click="selectedValue('veryround')"
          >
            <div class="text-center d-flex justify-content-center mt-1">Very Round</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { personCircle, scaleOutline, readerOutline } from 'ionicons/icons';
import ColorCircleBox from '@/shared/components/color-customize-box.vue';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';

const { isCustomizationSize } = useWindowSize();
const { currentTemplate, changeStylesKey } = characterStylizeStore();
const emits = defineEmits(['click']);

const profileShadow = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.profilePic.shadow');
});

const sectionShadow = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.infoSections.shadow');
});

const infoSectionCOlor = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.infoSections.color');
});
const profileBorder = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.profilePic.border');
});

const topBorder = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.topsection.border');
});
const topSectionShadow = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.topsection.shadow');
});

const topSectionColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.topsection.color');
});
const sectionBorder = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.infoSections.border');
});

const profileColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.profilePic.color');
});

const changedStyles = (name: string, selectedStyle: string, value: string) => {
  changeStylesKey(name, selectedStyle, value);
  if (!isCustomizationSize.value) {
    name === 'topsection' || name === 'profilePic'
      ? document
          .getElementById('topSection')
          ?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' })
      : name === 'infoSections'
      ? document
          .getElementById('infoSection')
          ?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' })
      : document.getElementById(name)?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
  }
};
const actionClicked = (select: any) => {
  emits('click', select);
};

const selectedValue = (value: string) => {
  changeStylesKey('sectionEdges', '', value);
};
const selectedTopSectionEdge = (value: string) => {
  changeStylesKey('topsection', 'edges', value);
};

const sectionEdges = computed(() => {
  return (
    get(currentTemplate.value, 'data.theme.styles.sectionEdges') ||
    get(currentTemplate.value, 'data.theme.styles.infoSections.edges')
  );
});
const topSectionEdges = computed(() => {
  return get(currentTemplate.value, 'data.theme.styles.topsection.edges');
});
</script>

<style lang="sass" scoped>
.set-edges
  background: rgb(234, 236, 246)

.dark .set-edges
  color: #000000 !important
.selected-edges
  background: #214163
  color: white

.set-shadow
  background: #eaecf6
  border-radius: 6px
.dark .set-shadow
  color: #000000 !important

.selected-shadow
  background: #214163
  color: white
  border-radius: 6px

.set-border
  background: #eaecf6
  border-radius: 6px
.dark .set-border
  color: #000000 !important
.selected-border
  background: #214163
  color: white
  border-radius: 6px
.dark .selected-border
  color: white !important


.title
  color: #214163
  font-size: 24px
  font-weight: bold
.dark .title
  color: #000000 !important

.sub-title
  color: #214163
  font-size: 16px
  font-weight: bold
.dark .sub-title
  color: #000000 !important
.text
  color: #214163
  font-size: 16px

.temp-row
  display: flex
  column-gap: 6px
  justify-content: space-between
  width: 100%
  &:first-child
    margin-left: 1px !important
.small-button
  display: inline-block !important
  width: 33%
  height: 25px
  font-size: 14px
  border: none
.middle-btn
  min-width: 33%
  width: 33%
.border-middle-btn
  min-width: 23%
  width: 23%
.border-last-btn
  min-width: 25%
  width: 25%
.last-btn
  width: 38% !important
  min-width: 38%
</style>
