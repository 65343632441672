<template>
  <div class="d-flex">
    <div class="title">
      <ion-icon class="icon mx-2" :icon="colorPaletteOutline" />
      Backgrounds
    </div>
  </div>
  <div class="d-flex px-2 mt-2" style="flex-direction: row">
    <div class="w-100">
      <div class="d-flex flex-column">
        <div class="text-color">Entire Page:</div>
        <div class="mt-1">
          <color-circle-box
            :value="entirePageColor"
            :is-background="true"
            @select="actionClicked"
            @changed="(color) => colorChanged(color, 'page')"
          />
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="text-color">Top Section:</div>
        <div class="mt-1">
          <color-circle-box
            :value="topSectionColor"
            :is-background="true"
            :is-left="true"
            name="top"
            @select="actionClicked"
            @changed="(color) => colorChanged(color, 'topSection')"
          />
        </div>
      </div>
    </div>

    <div class="w-100">
      <div class="d-flex flex-column">
        <div class="text-color">Buttons:</div>
        <div class="mt-1">
          <color-circle-box
            :is-background="true"
            :value="buttonColor"
            :is-right="true"
            name="button"
            @select="actionClicked"
            @changed="(color) => colorChanged(color, 'buttons')"
          />
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="text-color">Info Sections:</div>
        <div class="mt-1">
          <color-circle-box
            :value="infoColor"
            :is-background="true"
            :is-right="true"
            name="section"
            @select="actionClicked"
            @changed="(color) => colorChanged(color, 'infoSection')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { colorPaletteOutline } from 'ionicons/icons';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import ColorCircleBox from '@/shared/components/color-customize-box.vue';
import { mainStore } from '@/shared/pinia-store/main';

const { isCustomizationSize } = useWindowSize();

const { currentTemplate, changeThemeBackgroundsKey, template } = characterStylizeStore();
const emits = defineEmits(['click']);
const route = useRoute();
const entirePageColor = computed(() => {
  return (isEmpty(get(currentTemplate.value, 'template_applied')) || isEmpty(template.value)) &&
    isdark.value &&
    get(currentTemplate.value, 'data.theme.backgrounds.page') === '#F3F3F3' &&
    (route.name === 'character-profile-new' || route.name === 'character-profile-draft-new')
    ? '#010123'
    : get(currentTemplate.value, 'data.theme.backgrounds.page');
});

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});
const topSectionColor = computed(() => {
  return (isEmpty(get(currentTemplate.value, 'template_applied')) || isEmpty(template.value)) &&
    isdark.value &&
    get(currentTemplate.value, 'data.theme.backgrounds.topSection') === '#E6E6E6' &&
    (route.name === 'character-profile-new' || route.name === 'character-profile-draft-new')
    ? ' #17074C'
    : get(currentTemplate.value, 'data.theme.backgrounds.topSection');
});
const infoColor = computed(() => {
  return ((isEmpty(get(currentTemplate.value, 'template_applied')) || isEmpty(template.value)) &&
    isdark.value &&
    get(currentTemplate.value, 'data.theme.backgrounds.infoSection')) === '#E6E6E6' &&
    (route.name === 'character-profile-new' || route.name === 'character-profile-draft-new')
    ? '#17074C'
    : get(currentTemplate.value, 'data.theme.backgrounds.infoSection');
});

const colorChanged = (newColor: string, value: string) => {
  changeThemeBackgroundsKey(value, newColor);
  if (['infoSection', 'buttons'].includes(value)) {
    if (!isCustomizationSize.value)
      document.getElementById(value)?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
  }
};
const buttonColor = computed(() => {
  return get(currentTemplate.value, 'data.theme.backgrounds.buttons');
});
const actionClicked = (select: any) => {
  emits('click', select);
};
</script>

<style lang="sass" scoped>
.title
  color: #333333
  font-size: 22px
  font-weight: bold
.dark .title
  color: #000000 !important
.dark .text-color
  color: #000000 !important

.character-customize-key-info
  background: #eaecf6
  border-radius: 10px
.sub-title
  color: #333333
  font-size: 16px
  font-weight: bold
.small-circle
  width: 20px
  height: 20px
  border-radius: 20px
  background: red
  border: 1px solid #333333
.customize-btn
  --border-radius: 10px
  text-transform: uppercase
  --background: linear-gradient(197deg, rgb(174 56 229) 33.33%, rgb(222 24 215) 100%) !important
.template-btn
  --border-radius: 10px
  text-transform: uppercase

.character-profile-editor-key-info
  padding: 10px 18px
  background: #E6E6E6
  border-radius: 20px
  .label
    color: #333333
  .tags
    margin-top: 10px
    border-radius: 10px
    overflow: hidden
    ::v-deep
      input
        font-size: 14px !important

  .one-info
    margin-top: 20px
  .one-input
    margin-top: 10px
    border-radius: 10px !important
    ::v-deep
      input, textarea
        border: 0 !important
      textarea
        padding: 12px

.choose-options
  width: 190px
  @media(max-width: 370px)
    width: 200px
  @media(max-width: 250px)
    width: 150px
::v-deep .multiselect
  .multiselect__tags
    height: 26px !important
    min-height: 0px !important
    padding: 2px 40px 0 8px !important

  .multiselect__content-wrapper
    border-radius: 15px
  .multiselect__select
    height: 26px !important
    background: #41476c
    width: 26px !important
    border-radius: 6px !important
    line-height: 20px !important
    top: 0px !important
  .multiselect__select::before
    border-color: #fffafa transparent transparent transparent !important
</style>
