<template>
  <div class="top-bar-cust d-flex justify-content-center set-screen-margin">
    <div
      class="templates"
      :class="
        isEmpty(template)
          ? 'apply-templates'
          : !isEmpty(template) && get(route.query, 'tempId')
          ? 'try-on-temp'
          : 'applied-temp'
      "
    >
      <div class="px-2 py-2">
        <div class="d-flex justify-content-between">
          <ion-button class="back-button" @click="back">
            <i class="ti-arrow-left icon" />
            <!-- <ion-icon class="icon" :icon="arrowBackOutline" /> -->
          </ion-button>

          <ion-button
            v-if="!isEmpty(template)"
            class="w-100 switch-temp"
            style="--background: #00b4c5"
            @click="openModel"
          >
            <ion-icon class="icon" :icon="swapHorizontalOutline" />
            <div class="mx-1">Switch Template</div></ion-button
          >
          <ion-button
            v-else-if="isMobSmallScreen && isEmpty(template)"
            class="w-100 apply-btn"
            @click="openModel(false)"
            >Apply</ion-button
          >
          <div></div>
        </div>

        <themes-templates :used-temp="usedTemp" @dismiss="emits('applied')" @showtoast="getToast" />
      </div>
    </div>

    <stylize-character-modals
      :id="route.params.id"
      :is-open="isModalOpen"
      :is-switch="isSwitch"
      :customize="getCustomizmeData"
      @close="closeStylizeModal"
      @dismiss="dismissModal"
      @applied="appliedModal"
      @loadPage="loadCharProfile"
      @charTemp="getCharTemp"
    />
  </div>
</template>

<script lang="ts" setup>
import { arrowBackOutline, homeOutline, pencilOutline, saveOutline, swapHorizontalOutline } from 'ionicons/icons';
import ThemesTemplates from './customize-bar/themes/themes-templates.vue';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import StylizeCharacterModals from '@/shared/modals/StylizeCharacterModal.vue';
const { currentTemplate, customizeCharacter, template, deleteAppliedCustomizationTemp } = characterStylizeStore();

const props = defineProps({
  customize: {
    type: Object,
    default: {},
  },
});
const customize = toRef(props, 'customize');
const usedTemp = ref([]);
const isModalOpen = ref(false);
const isSwitch = ref(false);
const istoast = ref(false);
const route = useRoute();
const router = useRouter();

const emits = defineEmits(['showtoast', 'applied']);

onMounted(async () => {
  document.getElementById('scroll')?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
});

const back = () => {
  router.go(-1);
};

const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value < 1365;
});

const getCharTemp = (temp: any) => {
  usedTemp.value = temp;
};

const openModel = (switchVal: any) => {
  isModalOpen.value = true;
  isSwitch.value = switchVal;
};
const loadCharProfile = () => {
  isModalOpen.value = false;
  getRoute();
  deleteAppliedCustomizationTemp(true);
};
const getRoute = () => {
  route.name === 'character-stylize'
    ? router.push({ name: 'character-profile-new', params: { slug: get(customizeCharacter.value, 'entity.slug') } })
    : router.push({
        name: 'character-profile-draft-new',
        params: { id: get(customizeCharacter.value, 'entity_id') },
      });
};

const getToast = () => {
  emits('applied');
  getRoute();
  emits('showtoast', (istoast.value = true));
};

const dismissModal = () => {
  isModalOpen.value = false;
  emits('applied');
};

const closeStylizeModal = () => {
  isModalOpen.value = false;
};
const getCustomizmeData = computed(() => {
  return isSwitch.value ? customize.value : currentTemplate.value;
});

const appliedModal = () => {
  closeStylizeModal();

  if (!customizeCharacter.value?.entity?.slug) return;

  router.push({ name: 'character-profile-new', params: { slug: get(customizeCharacter.value, 'entity.slug') } });
  emits('showtoast', (istoast.value = true));
};
</script>

<style scoped lang="sass">
.top-bar-cust
  padding-top: var(--safe-ios-margin)
  .templates
    padding-top: var(--safe-ios-margin)
.set-screen-margin
  @media(min-width:1024px) and (max-width:1215px)
    margin-left: -260px
  @media(min-width:1365px)
    display: none !important
.apply-btn
  --border-radius: 20px
  height: 36px
  --background: linear-gradient(270deg, rgb(84 77 203) 3.33%, rgb(222 24 215) 100%) !important

.switch-temp
  --border-radius: 15px
  font-weight: 700
  height: 35px
.edit-temp
  color: black
  padding-top: 10px !important
  font-weight: bold
.dark .edit-temp
  color: black
.text
  color: black
  padding-top: 1px
.dark .text
  color: black
.back-button
  --background: #5727a0
  --border-radius: 11px
  height: 38px
  @media(max-width:1460px)
    width: 36px !important
    height: 37px !important
.icon
  font-size: 12px
  font-weight: bold

.dark .edit-text
  color: black !important
@media(max-width: 1460px)
  .applied-temp
    height: 183px !important
    width: 100% !important
  .try-on-temp
    height: 206px !important
    width: 100% !important
  .apply-templates
    height: 64px !important
    width: 100% !important

@media(max-width:560px)
  .applied-temp
    height: 183px !important
  .try-on-temp
    height: 225px !important
    width: 100% !important
@media(max-width:464px)
  .applied-temp
    height: 183px !important
  .try-on-temp
    height: 225px !important
    width: 100% !important

.templates
  max-width: 576px !important
  height: 194px
  position: fixed
  background: white
  top: 0
  width: 50%
  z-index: 2
  border-bottom-left-radius: 15px
  border-bottom-right-radius: 15px
</style>
