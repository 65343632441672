<template>
  <div v-if="isMobScreen && isStylize" style="position: fixed; right: 14px; max-width: 300px" class="customize-menu">
    <div class="d-flex flex-column mt-3">
      <div>
        <customize-tabs :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
        <div class="customize-content py-3">
          <div v-if="currentTab === 'themes'">
            <themes />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CustomizeTabs from './customize-bar/customize-tabs.vue';
import Themes from './customize-bar/themes/index.vue';
import { stylizeTabs } from '@/shared/statics/tabs';
import { Tab } from '@/shared/types/static-types';
const { width } = useWindowSize();

defineProps({
  id: {
    type: String,
    default: '',
  },
  templates: {
    type: Array,
    default: [],
  },
});

const windowHeight = ref('0px');
const tabs = ref(stylizeTabs);
const currentTab = ref('themes');
const tabIndex = ref(0);
const router = useRouter();
const route = useRoute();

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  router.replace({ name: 'character-stylize', query: { tab: value } });
};

onMounted(async () => {
  document.getElementById('scroll')?.scrollIntoView({ behavior: 'auto', block: 'nearest' });
  windowHeight.value = `${window.innerHeight}px`;
  window.addEventListener('resize', handleResize);
});
const isMobScreen = computed(() => {
  return width.value >= 1024;
});

const isStylize = computed(() => {
  return route.name === 'character-stylize' || route.name === 'character-draft-stylize';
});

const handleResize = () => {
  windowHeight.value = `${window.innerHeight}px`;
};
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped lang="sass">
.customize-content
  overflow: auto
  background: white
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px
  margin-right: 1px
  height: calc(v-bind(windowHeight) - 85px) !important
</style>
