<template>
  <div class="position-relative">
    <Chrome
      v-if="selecting"
      v-model="selectedColor"
      v-click-outside="() => ($emit('select', (selecting = false)), (selecting = false))"
      class="position-absolute picker"
    />
    <div
      class="colorr"
      :class="{ 'set-color-width': isBackground }"
      :style="{ background: selectedColor }"
      @click.prevent="activate"
    ></div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { reorderThreeOutline } from 'ionicons/icons';
import { isElementInViewport } from '@/shared/utils/ui';

@Options({
  name: 'ColorCustomizeBox',
})
export default class ColorCustomizeBox extends Vue {
  @Prop({ default: '#FFF' }) public value!: string;
  @Prop({ default: false }) public isDraggable!: boolean;
  @Prop({ default: false }) public isBackground!: boolean;
  public reorderThreeOutline = reorderThreeOutline;
  public selectedColor: any = '#FFF';
  public selecting = false;

  public windowWidth = window.innerWidth;
  @Watch('selecting')
  public selectingChanged() {
    try {
      this.$nextTick(() => {
        const pickerEl = document.querySelector('.vc-chrome') as HTMLElement;
        const menuEl = this.isMobSmallScreen
          ? (document.querySelector('.mobile-bottom-bar') as HTMLElement)
          : (document.querySelector('.customize-menu') as HTMLElement);

        if (!pickerEl || !menuEl) return; // selecting is now false
        const checkBool = !!this.isBackground;
        const { x, y } = isElementInViewport(pickerEl, menuEl, checkBool);
        if (!x) {
          this.isBackground ? (pickerEl.style.left = '-115px') : (pickerEl.style.left = '-205px');
        }
        if (!y) {
          this.isMobSmallScreen ? (pickerEl.style.top = '-120px') : (pickerEl.style.top = '-200px');
        }
      });
    } catch (error) {}
  }

  @Watch('value', { immediate: true })
  public change() {
    this.selectedColor = this.value;
  }

  @Watch('selectedColor')
  @Emit('changed')
  public changed() {
    try {
      if (this.selectedColor.hex) {
        return this.selectedColor.hex;
      }
      return this.selectedColor;
    } catch (error) {
      return this.selectedColor;
    }
  }

  @Emit('removed')
  public removed() {
    return this.value;
  }

  public activate() {
    if (!this.isDraggable) this.selecting = true;
    this.$emit('select', this.selecting);
  }

  public handleResize() {
    this.windowWidth = window.innerWidth;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 570;
  }

  mounted() {
    window.addEventListener('resize', this.handleResize);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="sass" scoped>
.picker
  margin-top: 26px
  z-index: 11111
.float-circle-btn
  background-color: rgba(0,0,0,0.5)
  --border-radius: 100% !important
  border-radius: 100% !important
  width: 28px
  height: 28px
  --padding: 0
  --padding-start: 0
  --padding-end: 0
  right: calc(-100% + 38px)
  top: 0px
  position: relative
  z-index: 1
.remove-color-btn
  background-color: rgba(0,0,0,0.5)
  --border-radius: 100% !important
  border-radius: 100% !important
  width: 20px !important
  height: 20px !important
  right: calc(-100% + 28px)
.colorr
  width: 20px
  height: 20px
  font-size: 10px
  position: relative
  margin-bottom: 21px
  cursor: pointer
  border-radius: 20px !important
  border: 3px solid black
.value
  width: 100%
  position: absolute
  bottom: 5px
  text-align: center
  font-size: 11px
.drag-indicator
  position: relative
  text-align: center
  height: 24px
  width: 24px
  border-bottom-right-radius: 5px
  background-color: rgba(0, 0, 0, 0.5)
  color: white !important
.color-handle
  cursor: move !important
  -webkit-user-drag: element
  &:hover
    opacity: 0.75
.set-color-width
  width: calc(100% - 8px) !important
  height: 63px !important
  border-radius: 12px !important
</style>
